<template>
  <div id="escuela-especialidad">
    <v-container grid-list-xl fluid>
      <header-title title="Escuela - Especialidad"></header-title>
      <v-row class="mt-1">
                <v-col cols="12" sm="4">
                    <v-autocomplete
                      label="Seleccione una Gestión"
                      :items="gestiones"
                      v-model="gestion"
                      v-on:change="getDatos"
                    ></v-autocomplete>
                </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            label="Seleccione ESFM/UA"
            :items="instituciones"
            v-model="institucion_id"
            v-on:change="getInstitucionEspecialidad"
            item-text="nombre"
            item-value="id"
          ></v-autocomplete>

          <template v-if="institucion_especialidades.length > 0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nro
                    </th>
                    <th class="text-left">
                      Descripción
                    </th>
                    <!-- <th class="text-left">
                      Año
                    </th> -->
                    <th class="text-left">
                      Acción
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in institucion_especialidades"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.especialidad.descripcion }}</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="red"
                            @click="removeEspecialidad(item.id)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-arrow-right-bold-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Quitar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template v-else>
            <p
              v-if="institucion_id == ''"
              class="red--text text--lighten-2"
              :value="true"
              type="warning"
            >
              Debe seleccionar un ESFM/UA.
            </p>
            <p
              v-else
              class="red--text text--lighten-2"
              :value="true"
              type="info"
              outline
            >
              No existe especialidades agregados .
            </p>
          </template>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            clearable
            single-line
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="especialidades"
            :loading="loading"
            :search="search"
            calculate-widths
            no-data-text="No existen registros"
            no-results-text="Sin resultados"
            item-key="name"
            class="elevation-1"
            :items-per-page="15"
            v-cloak
          >
            <template slot="item.nro" slot-scope="props">
              {{ props.index + 1 }}
            </template>
            <template v-slot:item.actions="{ item }">
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="success"
                      @click="createInstitucionEspecialidad(item.id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-left-bold-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="mconfirm" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Confirmación</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de quitar la especialidad?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteItem()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="mconfirm = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
    >
      {{ snack.text }}
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "escuela-especialidad",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      currentPage: 0,
      menu: false,
      loading: false,
      loading_speciality: false,
      btn_loading: false,
      headers: [
        { text: "Nro ", value: "nro", sortable: false },
        { text: "Descripción ", value: "descripcion" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      instituciones: [],
      especialidades: [],
      institucion_especialidades: [],
      institucion_id: "",
      especialidad_id: "",
      institucion_especialidad_id: "",
      anho_formacion: 1,
      mdialog: false,
      mconfirm: false,
      snack: {
        state: false,
        color: "success",
        text: "",
      },
      gestiones: [],
      gestion:"",
    };
  },
  created() {},
  mounted() {
    if (Service.getUser()) {
      if (Service.getUser().role == 1) {
        this.getInstituciones();
        this.getEspecialidades();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
    this.gestiones = ["2022", "2023"];
    this.gestion = this.gestiones[0];
    
  },
  methods: {
    getDatos(){
      this.institucion_id= "";
      this.institucion_especialidades= [];
    },
    getInstituciones() {
      this.loading = true;
      axios
        .get(Service.getBase() + "institucion/activo", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.instituciones = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    getEspecialidades() {
      axios
        .get(Service.getBase() + "especialidades/activo", Service.getHeader())
        .then((response) => {
          this.especialidades = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },

    getInstitucionEspecialidad() {
      this.loading_speciality = true;
      axios
        .get(
          Service.getBase() +
            "institucion/especialidades/" +
            this.institucion_id+"/"+this.gestion,
          Service.getHeader()
        )
        .then((response) => {
          this.loading_speciality = false;
          this.institucion_especialidades = response.data;
        })
        .catch((error) => {
          this.loading_speciality = false;
          console.error("Error al cargar registros", error);
        });
    },

    createInstitucionEspecialidad(especialidad_id) {
      if (this.institucion_id != "") {
        this.btn_loading = true;
        axios
          .post(
            Service.getBase() + "institucion/especialidad",
            {
              institucion_id: this.institucion_id,
              especialidad_id: especialidad_id,
              anho_formacion: this.anho_formacion,
              estado: true,
              gestion : this.gestion,
            },
            Service.getHeader()
          )
          .then((response) => {
            this.btn_loading = false;
            if (response.status == 200) {
              this.toast("warning", response.data);
            } else {
              this.toast("success", response.data);
            }
            this.mdialog = false;
            this.getInstitucionEspecialidad();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast("error", "No se pudo guardar");
          });
      } else {
        this.toast("warning", "Seleccione ESFM/UA");
      }
    },

    removeEspecialidad(id) {
      this.institucion_especialidad_id = id;
      this.mconfirm = true;
    },

    deleteItem() {
      this.btn_loading = true;
      axios
        .delete(
          Service.getBase() +
            "institucion/especialidad/" +
            this.institucion_especialidad_id,
          Service.getHeader()
        )
        .then((response) => {
          this.btn_loading = false;
          this.toast("success", "Registro eliminado");
          this.mconfirm = false;
          this.getInstitucionEspecialidad();
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast("error", "Registro en uso o no existente");
        });
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
